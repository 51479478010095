import { SetPartialStateAction, useHookstate } from "@hookstate/core"

import { createAspState } from "../createAspState"
import {
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"

import { ProductVariant, ProductImage } from "src/states/shop/product"

export type ProductFeaturesProduct = ProductVariant & {
  image?: ProductImage
}

export type ProductFeaturesForm = AdvancedForms[]

export type ProductFeaturesFormField = AdvancedFormFieldProps[]

export interface ProductFeatures {
  open: boolean
  product: ProductFeaturesProduct
  form: ProductFeaturesForm
}

export const defaultState = {
  open: false,
  form: [
    {
      id: "personals",
      fields: [
        {
          position: 0,
          type: "text",
          label: "PO-Nummer",
          name: "poNumber",
          required: true,
        },
        {
          position: 1,
          type: "number",
          name: "orderQuantity",
          label: "Bestellmenge",
        },
        {
          position: 2,
          type: "checkbox",
          label: "Ich akzeptiere die Vertragsbedingungen",
          name: "legals",
          required: true,
        },
        {
          position: 3,
          type: "file",
          label: "Bitte laden Sie das ausgefüllte Formular hoch",
          name: "file",
        },
      ],
    },
  ],
} as ProductFeatures

const state = createAspState<ProductFeatures>(
  { ...defaultState },
  "shop-product-features",
)

/**
 * Changes complete state
 * @param data account data
 */
export const setProductFeaturesForm = (data: AdvancedForms[]) =>
  state.form.set(data)

/**
 * Changes a existing field
 * @param index the index of the form
 * @param name the name of the field
 * @param items the changed attributes of the field
 */
export const mergeProductFeaturesFormField = (
  index: number,
  items: AdvancedFormFieldProps,
  name: string | null,
) => {
  const fieldIndex = state.form
    .nested(index)
    .fields.get()
    ?.findIndex((e) => e.name === name)
  state.form
    .nested(index)
    .fields.nested(fieldIndex)
    ?.merge({
      ...items,
    })
}

/**
 * Updates the state
 * @param value the updated state
 */
export const mergeProductFeatures = (
  value: SetPartialStateAction<ProductFeatures>,
) => state.merge(value)

export const setProductFeatures = (value: ProductFeatures) => state.set(value)

/**
 * Resets the complete product features state
 */
export const resetProductFeatures = () =>
  state.set({ ...defaultState, open: false })

/**
 * Resets the product features form
 */
export const resetProductFeaturesForm = () =>
  state.form.set([...defaultState.form])

/**
 * Returns complete ProductFeatures added to the state.
 * @returns the ProductFeatures
 */
export const getProductFeatures = async () => state.get()

/**
 * React hook to receive complete ProductFeatures state.
 * @returns the ProductFeatures
 */
export const useProductFeatures = () => {
  return useHookstate(state).get()
}
