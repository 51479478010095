import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import EditlIcon from "@mui/icons-material/Edit"
import React, { FC, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { Tabs } from "src/components/common/tabs"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { create } from "src/helpers/bem"
import {
  useCoExhibitorInvite,
  mergeCoExhibitorInvite,
} from "src/states/coExhibitorInvite"

import { TranslationMessages } from "src/translations"

import styles from "./AddCoExhibitorDialog.module.scss"
import { AddCoExhibitorEmailInvite } from "./addCoExhibitorEmailInvite"
import { AddCoExhibitorForm } from "./addCoExhibitorForm"

const bem = create(styles, "AddCoExhibitorDialog")

export type AddCoExhibitorDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const AddCoExhibitorDialog: FC<AddCoExhibitorDialogProps> = ({
  messages,
  style,
}) => {
  const m = messages.pages.booking.detail.actions.addCoExhibitor
  const { open } = useCoExhibitorInvite()
  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="xl"
      open={useMemo(() => open, [open])}
      style={style}
      title={m.title}
      dialogButtons={[
        {
          title: m.cancel,
          variant: "text",
        },
      ]}
      onClose={() =>
        mergeCoExhibitorInvite({
          open: !open,
        })
      }
    >
      <p className={bem("description")}>{m.description}</p>
      <Tabs
        id="add-co-exhibitor"
        items={[
          {
            label: m.emailInvite.title,
            icon: <AttachEmailIcon />,
            iconPosition: "start",
            node: <AddCoExhibitorEmailInvite messages={messages} />,
          },
          {
            label: m.selfEntry.title,
            icon: <EditlIcon />,
            iconPosition: "start",
            node: <AddCoExhibitorForm messages={messages} />,
          },
        ]}
      />
    </Dialog>
  )
}
