import ExpandMore from "@mui/icons-material/ExpandMore"
import MatAccordion, {
  AccordionProps as MatAccordionProps,
} from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import React, { FC, SyntheticEvent, ReactNode, useState } from "react"

import { Switch, SwitchProps } from "src/components/common/switch"

import { create } from "src/helpers/bem"

import styles from "./Accordion.module.scss"

const bem = create(styles, "Accordion")

export type AccordionClassNames = {
  wrapper?: string
  tab?: string
  content?: string
  details?: string
}

export type AccordionProps = {
  id: string
  detailsClassName?: string
  label: ReactNode | string
  classNames?: AccordionClassNames
  description?: string
  switch?: SwitchProps
} & MatAccordionProps

export const Accordion: FC<AccordionProps> = ({
  id,
  detailsClassName,
  label,
  classNames,
  description,
  children,
  defaultExpanded,
  onChange,
  ...props
}) => {
  const [expanded, setExpanded] = useState<string | boolean>(
    defaultExpanded ? id : false,
  )

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      onChange?.(event, isExpanded)
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <MatAccordion
      {...props}
      expanded={expanded === id}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        aria-controls={`${id}-content`}
        className={bem("tab__wrapper", undefined, classNames?.wrapper)}
        expandIcon={<ExpandMore />}
        id="panel1bh-header"
        classes={{
          content: classNames?.content,
        }}
      >
        <div className={bem("tab", undefined, classNames?.tab)}>
          {typeof label === "object" ? (
            label
          ) : (
            <h4 className={bem("tab__headline")}>{label}</h4>
          )}
          {description && (
            <p
              className={bem("tab__description")}
              dangerouslySetInnerHTML={{ __html: description ?? "" }}
            />
          )}
        </div>
        {props.switch ? (
          <Switch {...props.switch} className={bem("switch")} />
        ) : null}
      </AccordionSummary>
      <AccordionDetails className={detailsClassName}>
        {children}
      </AccordionDetails>
    </MatAccordion>
  )
}
