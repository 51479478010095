import { ProductCardProps } from "src/components/common/productCard"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export type Product = ProductCardProps & {
  id: number
  category: string
  subCategory: string
  variants?: boolean
}

export type Products = {
  loading: boolean
  // TODO: Replace type definition with API definition
  products: Product[]
  defaultProducts: Product[]
}

const { getValue, setValue, mergeValue, useValue } =
  createSimpleGlobalState<Products>(
    {
      loading: false,
      products: [
        {
          id: 1,
          category: "technic-and-logistic",
          subCategory: "electrical-supply",
          tag: "Bestellfrist 01.12.2023",
          title: "Elektroanschluss Basic 3 kW inkl. Verbrauch",
          description:
            "inkl. 5-poligen 16A CEE-Anschluss - Abrechnung zum Pauschalpreis",
          price: 169.0,
          variants: true,
          image: {
            src: "/mocks/Elektroversorgung_inkl-Verbrauch_Stempel.png",
            alt: "Elektroanschluss Basic 3 kW",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 2,
          category: "technic-and-logistic",
          subCategory: "electrical-supply",
          title: "Erdungsanschluss 16 mm²",
          tag: "Bestellfrist 15.11.2023",
          price: 149.0,
          variants: true,
          image: {
            src: "/mocks/Erdungsanschluss.png",
            alt: "Erdungsanschluss 16 mm²",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 3,
          category: "technic-and-logistic",
          subCategory: "telephony",
          tag: "Bestellfrist 01.12.2023",
          title: "Anschluss für eigenes Endgerät/ Telefon",
          price: 199.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-fuer-eigenes-Endgeraet.png",
            alt: "Endgerät/Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 4,
          category: "technic-and-logistic",
          subCategory: "telephony",
          title: "Anschl. inkl. schnurgebundenen Telefon",
          description:
            "Einzelanschluss (T-Net) inkl. einem schnurgebundenen Telefon mit Display, inklusive Tarifeinheiten.",
          price: 248.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-inkl-schnurgebundenen-Telefon.png",
            alt: "Anschl. inkl. schnurgebundenen Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 5,
          category: "technic-and-logistic",
          subCategory: "telephony",
          title: "Anschluss inkl. schnurlosen Telefon",
          description:
            "Einzelanschluss (T-Net) inkl. einem schnurlosen Telefon mit Display, inklusive Tarifeinheiten.",
          price: 285.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-inkl-schnurlosen-Telefon.png",
            alt: "Anschluss inkl. schnurlosen Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 6,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim-color",
          title: "Systemstand Typ Slim Color",
          description:
            "Bringen Sie Farbe ins Spiel! Tauchen Sie den Teppich, die Wände und Blende Ihres Systemstands Typ Slim Color in eine unserer 8 knalligen Farbvarianten.",
          price: 99.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-Slim-Color.gif",
            alt: "Systemstand Typ Slim Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 7,
          category: "stand-construction",
          subCategory: "systemstand-typ-a-color",
          title: "Systemstand Typ A Color",
          description:
            "Wählen Sie aus unseren 8 auffälligen Farbvarianten Ihren Favoriten für Teppich, Wände und Blende.",
          price: 119.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-A.gif",
            alt: "Systemstand Typ A Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 8,
          category: "stand-construction",
          subCategory: "systemstand-typ-b-color",
          title: "Systemstand Typ B Color",
          description:
            "Blickfang hoch 2! Der Systemstand Typ B Color beeindruckt nicht nur mit seiner auffälligen Strukturdecke, sondern auch mit knallbunten Wänden, Teppich und Blende.",
          price: 149.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-B.gif",
            alt: "Systemstand Typ B Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 9,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim",
          title: "Systemstand Typ Slim",
          description:
            'Reduziert auf das Wesentliche: Der Systemstand Typ "Slim" ist eine der einfachsten und gleichzeitig effizientesten Standbau-Lösungen.',
          price: 65.0,
          unit: "Stück",
          image: {
            src: "/mocks/Slim_Basis.png",
            alt: "Systemstand Typ Slim",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 10,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim",
          title: "Standbau Upgrade Lighted",
          description:
            "Mit dem Upgrade LIGHTED wird die obere Füllung der zwei Turmelemente - die sich jeweils an den freien Wandenden befinden – mit einem Digitalprint/Logo versehen und hinterleuchtet.",
          price: 699.0,
          unit: "Stück",
          image: {
            src: "/mocks/Slim_Lighted_Basic.png",
            alt: "Standbau Upgrade Lighted",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
      ],
      defaultProducts: [
        {
          id: 1,
          category: "technic-and-logistic",
          subCategory: "electrical-supply",
          tag: "Bestellfrist 01.12.2023",
          title: "Elektroanschluss Basic 3 kW inkl. Verbrauch",
          description:
            "inkl. 5-poligen 16A CEE-Anschluss - Abrechnung zum Pauschalpreis",
          price: 169.0,
          variants: true,
          image: {
            src: "/mocks/Elektroversorgung_inkl-Verbrauch_Stempel.png",
            alt: "Elektroanschluss Basic 3 kW",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 2,
          category: "technic-and-logistic",
          subCategory: "electrical-supply",
          title: "Erdungsanschluss 16 mm²",
          tag: "Bestellfrist 15.11.2023",
          price: 149.0,
          variants: true,
          image: {
            src: "/mocks/Erdungsanschluss.png",
            alt: "Erdungsanschluss 16 mm²",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 3,
          category: "technic-and-logistic",
          subCategory: "telephony",
          tag: "Bestellfrist 01.12.2023",
          title: "Anschluss für eigenes Endgerät/ Telefon",
          price: 199.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-fuer-eigenes-Endgeraet.png",
            alt: "Endgerät/Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 4,
          category: "technic-and-logistic",
          subCategory: "telephony",
          title: "Anschl. inkl. schnurgebundenen Telefon",
          description:
            "Einzelanschluss (T-Net) inkl. einem schnurgebundenen Telefon mit Display, inklusive Tarifeinheiten.",
          price: 248.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-inkl-schnurgebundenen-Telefon.png",
            alt: "Anschl. inkl. schnurgebundenen Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 5,
          category: "technic-and-logistic",
          subCategory: "telephony",
          title: "Anschluss inkl. schnurlosen Telefon",
          description:
            "Einzelanschluss (T-Net) inkl. einem schnurlosen Telefon mit Display, inklusive Tarifeinheiten.",
          price: 285.0,
          unit: "Stück",
          image: {
            src: "/mocks/Anschluss-inkl-schnurlosen-Telefon.png",
            alt: "Anschluss inkl. schnurlosen Telefon",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 6,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim-color",
          title: "Systemstand Typ Slim Color",
          description:
            "Bringen Sie Farbe ins Spiel! Tauchen Sie den Teppich, die Wände und Blende Ihres Systemstands Typ Slim Color in eine unserer 8 knalligen Farbvarianten.",
          price: 99.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-Slim-Color.gif",
            alt: "Systemstand Typ Slim Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 7,
          category: "stand-construction",
          subCategory: "systemstand-typ-a-color",
          title: "Systemstand Typ A Color",
          description:
            "Wählen Sie aus unseren 8 auffälligen Farbvarianten Ihren Favoriten für Teppich, Wände und Blende.",
          price: 119.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-A.gif",
            alt: "Systemstand Typ A Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 8,
          category: "stand-construction",
          subCategory: "systemstand-typ-b-color",
          title: "Systemstand Typ B Color",
          description:
            "Blickfang hoch 2! Der Systemstand Typ B Color beeindruckt nicht nur mit seiner auffälligen Strukturdecke, sondern auch mit knallbunten Wänden, Teppich und Blende.",
          price: 149.0,
          unit: "Stück",
          image: {
            src: "/mocks/Systemstand-Typ-B.gif",
            alt: "Systemstand Typ B Color",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 9,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim",
          title: "Systemstand Typ Slim",
          description:
            'Reduziert auf das Wesentliche: Der Systemstand Typ "Slim" ist eine der einfachsten und gleichzeitig effizientesten Standbau-Lösungen.',
          price: 65.0,
          unit: "Stück",
          image: {
            src: "/mocks/Slim_Basis.png",
            alt: "Systemstand Typ Slim",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
        {
          id: 10,
          category: "stand-construction",
          subCategory: "systemstand-typ-slim",
          title: "Standbau Upgrade Lighted",
          description:
            "Mit dem Upgrade LIGHTED wird die obere Füllung der zwei Turmelemente - die sich jeweils an den freien Wandenden befinden – mit einem Digitalprint/Logo versehen und hinterleuchtet.",
          price: 699.0,
          unit: "Stück",
          image: {
            src: "/mocks/Slim_Lighted_Basic.png",
            alt: "Standbau Upgrade Lighted",
          },
          link: {
            href: "/de/produkt-details/?id=1",
          },
        },
      ],
    },
    "shop-products",
  )

export {
  getValue as getProducts,
  setValue as setProducts,
  mergeValue as mergeProducts,
  useValue as useProducts,
}
