import { SwipeableDrawer, SwipeableDrawerProps } from "@mui/material"
import React, { FC } from "react"

export type AnchorProps = "left" | "top" | "right" | "bottom"

export type DrawerProps = SwipeableDrawerProps

export const Drawer: FC<DrawerProps> = ({ children, ...props }) => (
  <SwipeableDrawer {...props}>{children}</SwipeableDrawer>
)
