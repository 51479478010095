import { SetPartialStateAction, useHookstate } from "@hookstate/core"

import { createAspState } from "src/states/createAspState"

export function createSimpleGlobalState<T>(
  initialValue: T,
  localStorageKey?: string,
) {
  const state = createAspState<T>(initialValue, localStorageKey)
  const getValue = () => state.get()
  const setValue = (value: T) => state.set(value)
  const mergeValue = (value: SetPartialStateAction<T>) => state.merge(value)
  const resetValue = () => state.set(initialValue)
  const useValue = () => {
    return useHookstate(state).value
  }

  return {
    getValue,
    setValue,
    useValue,
    mergeValue,
    resetValue,
  }
}
