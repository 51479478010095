import xrmApi from "src/services/xrmApi"

export const getSalesorder = async () => {
  return xrmApi
    .get(`/salesorder?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}

export const getSalesorderById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.get(`/salesorder/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const getCoExhibitorSalesordersById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi
    .get(`/salesorder/children?salesorderId=${id}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}

export const deleteSalesorderById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.delete(`/salesorder/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
