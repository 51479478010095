import {
  AnonymousCredential,
  BlobDeleteOptions,
  BlockBlobParallelUploadOptions,
  ContainerClient,
} from "@azure/storage-blob"

import xrmApi from "src/services/xrmApi"

// AzureAccountLocation/+salesorderId+fileName+sasToken
export async function getBlobUri(
  containerName: string,
  fileName: string,
  salesorderId: string,
  storage?: string,
  target?: string,
  features?: string,
) {
  const sasToken = await getSasToken(salesorderId)
  window.open(
    `${
      storage ?? process.env.NEXT_PUBLIC_BLOB_STORAGE
    }${containerName}${fileName}${sasToken}`,
    target,
    features,
  )
}

export const getSasToken = async (salesorderId: string) => {
  return xrmApi
    .get(`/Blob/token?salesorderId=${salesorderId}`)
    .then((res) => res?.data.token)
}

function getContainerClient(containerName: string, sas: string) {
  // Anonymous access is forbidden for our blob storage.
  // Nevertheless, AnonymousCredential is used when using sas tokens, see https://github.com/Azure/azure-sdk-for-js/tree/main/sdk/storage/storage-blob#with-sas-token
  return new ContainerClient(
    getContainerUri(containerName, sas),
    new AnonymousCredential(),
  )
}

// AzureAccountLocation/+salesorderId+sasToken
function getContainerUri(containerName: string, sas: string): string {
  return `${process.env.NEXT_PUBLIC_BLOB_STORAGE}/${containerName}${sas}`
}

export const postBlob = async (
  salesorderId: string,
  file: Blob,
  fileName: string,
  formControl: string,
) => {
  const options: BlockBlobParallelUploadOptions = {
    maxSingleShotSize: 4 * 1024 * 1024,
    blobHTTPHeaders: { blobContentType: file.type },
    metadata: {
      category: "Form",
      control: formControl,
    },
  }

  // New SAS token
  const azureToken = await getSasToken(salesorderId)

  // Contains the destination (folder)
  const containerClient = getContainerClient(salesorderId, azureToken)

  // Handles the following upload
  const blockBlobClient = containerClient.getBlockBlobClient(fileName)

  // Upload
  if (file != null) {
    return blockBlobClient.uploadData(file, options)
  }
}

export const deleteBlob = async (salesorderId: string, fileName: string) => {
  // New SAS token
  const azureToken = await getSasToken(salesorderId)

  // Contains the destination (folder)
  const containerClient = getContainerClient(salesorderId, azureToken)

  // Handles the following upload
  const blockBlobClient = containerClient.getBlockBlobClient(fileName)

  const options: BlobDeleteOptions = {
    deleteSnapshots: "include",
  }

  // Delete
  if (fileName != null) {
    return blockBlobClient.deleteIfExists(options)
  }
}

export const getFilesById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.get(`/file/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const deleteFileById = async (
  id?: string | null,
  salesorderId?: string | null,
) => {
  if (!id || !salesorderId) {
    return null
  }
  return xrmApi
    .delete(`/file?fileId=${id}&salesorderId=${id}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}
