import React, { FC, memo } from "react"

import { Icon } from "src/components/common/icon"
import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"

import { GET_PAGE_page_sidebar_documents_files } from "src/types/wordpress/generated/GET_PAGE"

import styles from "./DocumentBox.module.scss"

const bem = create(styles, "InfoBox")

export type DocumentBoxProps = {
  title?: string | null
  items: (GET_PAGE_page_sidebar_documents_files | null)[]
}

export const DocumentBox: FC<DocumentBoxProps> = memo(({ title, items }) => (
  <div className={bem()}>
    {title && <span className={bem("title")}>{title}</span>}
    <ul className={bem("items")}>
      {items &&
        items.map((item, i) => (
          <li key={`${i}-${item?.name}`} className={bem("item")}>
            <Icon className={bem("item__icon")} name="circleArrowRight" />
            <Link
              href={item?.src ?? ""}
              target="_blank"
              classNames={{
                link: bem("item__link"),
                label: bem("item__label"),
              }}
            >
              {item?.name}
            </Link>
          </li>
        ))}
    </ul>
  </div>
))

DocumentBox.displayName = "DocumentBox"
