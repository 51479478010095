import { SelectChangeEvent } from "@mui/material/Select"
import React, { FC, ChangeEvent, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { InputField } from "src/components/common/inputfield"
import { Select } from "src/components/common/select"
import { create } from "src/helpers/bem"

import { postPermission } from "src/queriesXRM/permission"
import { useBooking } from "src/states/bookingDetail"
import {
  useBookingDetailPermissions,
  getBookingDetailPermissions,
  mergeBookingDetailPermission,
  mergeBookingDetailPermissions,
} from "src/states/bookingDetailPermissions"

import { TranslationMessages } from "src/translations"
import { xRMApiPermission, xRMApiPermissionRole } from "src/types/xRM"

import styles from "./PermissionTableActionsAddUserDialog.module.scss"

const bem = create(styles, "PermissionTableActionsAddUserDialog")

export type PermissionTableActionsAddUserDialogProps = {
  messages: TranslationMessages
}

export const PermissionTableActionsAddUserDialog: FC<
  PermissionTableActionsAddUserDialogProps
> = ({ messages }) => {
  const m = messages.pages.booking.detail.actions.permissions.actions.add
  const { booking } = useBooking()
  const { addUser } = useBookingDetailPermissions()
  const AddUser = {
    open: addUser.open,
    email: addUser.email,
    emailErrorText: addUser?.emailErrorText ?? "",
    role: addUser.role,
    roleErrorText: addUser?.roleErrorText,
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    mergeBookingDetailPermissions({
      addUser: {
        ...AddUser,
        email: e.target.value,
      },
    })
  }

  const handleValidationEmail = (error?: string) => {
    mergeBookingDetailPermissions({
      addUser: {
        ...AddUser,
        emailErrorText: error,
      },
    })
  }

  const handleChangeRole = (e: SelectChangeEvent<unknown>) => {
    mergeBookingDetailPermissions({
      addUser: {
        ...AddUser,
        role: e?.target?.value as xRMApiPermissionRole,
      },
    })
  }

  const handleValidationRole = (error?: string) => {
    mergeBookingDetailPermissions({
      addUser: {
        ...AddUser,
        roleErrorText: error,
      },
    })
  }

  const onAddUser = () => {
    postPermission({
      salesorderId: booking?.id ?? undefined,
      email: AddUser.email,
      role: AddUser.role,
    }).then((res) => {
      const { permissions } = getBookingDetailPermissions()
      const Permissions = JSON.parse(
        JSON.stringify(permissions),
      ) as xRMApiPermission[]
      const permissionExist = Permissions.some((el) => el.id === res.id)
      if (permissionExist) {
        mergeBookingDetailPermissions({
          addUser: {
            open: false,
            email: "",
            role: "Administrator",
          },
        })
        mergeBookingDetailPermission(res.id, res)
        return
      }
      mergeBookingDetailPermissions({
        addUser: {
          open: false,
          email: "",
          role: "Administrator",
        },
        permissions: [...Permissions, res],
      })
    })
  }

  return (
    <Dialog
      closeOnClick
      open={useMemo(() => addUser.open, [addUser.open])}
      title={m.title}
      dialogButtons={[
        {
          title: m.buttons.add,
          variant: "text",
          onClick: onAddUser,
        },
        {
          title: m.buttons.cancel,
          variant: "text",
        },
      ]}
      onClose={() =>
        mergeBookingDetailPermissions({
          addUser: {
            open: !addUser.open,
            email: addUser.email,
            role: addUser.role,
          },
        })
      }
    >
      <div className={bem("form")}>
        <div className={bem("form__field")}>
          <InputField
            required
            errorText={addUser.emailErrorText}
            label={m.form.email}
            name="email"
            type="email"
            value={addUser.email}
            onChange={handleChangeEmail}
            onValidate={handleValidationEmail}
          />
        </div>
        <div className={bem("form__field")}>
          <Select
            required
            errorText={addUser.roleErrorText}
            label={m.form.role}
            messages={messages}
            name="role"
            value={addUser.role}
            items={[
              {
                key: "Administrator",
                value:
                  messages.pages.booking.detail.actions.permissions.table.role
                    .items.Administrator,
              },
            ]}
            onChange={handleChangeRole}
            onValidate={handleValidationRole}
          />
        </div>
      </div>
    </Dialog>
  )
}
