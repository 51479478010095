import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import CloseIcon from "@mui/icons-material/Close"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined"
import React, { FC } from "react"

import { LazyImage } from "src/components/common/lazyImage"

import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"

import {
  useCart,
  addProductToCart,
  removeProductFromCart,
  removeCompleteProductFromCart,
} from "src/states/shop/cart"
import { TranslationMessages } from "src/translations"

import styles from "./ShopCartDialogProducts.module.scss"

const bem = create(styles, "ShopCartDialogProducts")

export type ShopCartDialogProductsProps = {
  messages: TranslationMessages
}

export const ShopCartDialogProducts: FC<ShopCartDialogProductsProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.cart.dialog
  const { products } = useCart()
  return (
    <div className={bem()}>
      {products && products?.length > 0 ? (
        products.map((el, i) => {
          const price = el?.discountedPrice ?? el?.price
          const locale = el?.locale
          const currency = el?.currency as Intl.NumberFormatOptions
          const buttonModifier = {
            gray: true,
          }
          return (
            <div key={`cart-product-${i}`} className={bem("item")}>
              <div className={bem("counter")}>
                <button
                  className={bem("counter__button")}
                  onClick={() => addProductToCart(el)}
                >
                  <AddOutlinedIcon className={bem("counter__icon")} />
                </button>
                <span className={bem("counter__sum")}>{el?.sum}</span>
                <button
                  className={bem("counter__button", buttonModifier)}
                  onClick={() => removeProductFromCart(el?.id)}
                >
                  <RemoveOutlinedIcon
                    className={bem("counter__icon", buttonModifier)}
                  />
                </button>
              </div>
              <div className={bem("content")}>
                <div className={bem("col")}>
                  {el?.image?.src ? (
                    <LazyImage
                      alt={el?.image?.alt ?? el?.title}
                      className={bem("image")}
                      src={el?.image?.src}
                    />
                  ) : (
                    <NoPhotographyIcon
                      className={bem("image__placeholder")}
                      color="disabled"
                    />
                  )}
                </div>
                <div className={bem("col")}>
                  <h5 className={bem("title")}>{el?.title}</h5>
                  <span className={bem("sum")}>
                    {formatPrice(price, locale, currency)} x {el?.sum}
                  </span>
                  <span className={bem("total")}>
                    {formatPrice(price * el?.sum, locale, currency)}
                  </span>
                </div>
              </div>
              <button
                className={bem("remove")}
                onClick={() => removeCompleteProductFromCart(el?.id)}
              >
                <CloseIcon className={bem("remove__icon")} />
              </button>
            </div>
          )
        })
      ) : (
        <p className={bem("noData")}>{m.noProducts}</p>
      )}
    </div>
  )
}
