import { IconButtonProps } from "@mui/material/IconButton"
import MatTable, { TableProps as MatTableProps } from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"

import React, { FC, MouseEvent, ChangeEvent, useState } from "react"

import { AdvancedFormFieldProps } from "src/components/common/advancedForm"
import {
  DataTableItems,
  DataTableTranslations,
} from "src/components/common/dataTable"
import { InputField } from "src/components/common/inputfield"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./Table.module.scss"
import { TableHead } from "./TableHead"
import { TableRow } from "./TableRow"

const bem = create(styles, "Table")

type Without<T, K> = Pick<T, Exclude<keyof T, K>>

export type TableOrder = "asc" | "desc"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: TableOrder,
  orderBy: Key,
): (
  a: {
    [key in Key]:
      | number
      | string
      | DataTableItems[]
      | AdvancedFormFieldProps
      | undefined
  },
  b: {
    [key in Key]:
      | number
      | string
      | DataTableItems[]
      | AdvancedFormFieldProps
      | undefined
  },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const filteredArray = array.filter((el) => !Array.isArray(el))
  const stabilizedThis = filteredArray.map(
    (el, index) => [el, index] as [T, number],
  )
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export type TableData = {
  name: string
  [p: string]:
    | number
    | string
    | DataTableItems[]
    | AdvancedFormFieldProps
    | undefined
}

export type TableTranslationItem = {
  [p: string]: number | string
}

export type TableTranslationHead = {
  label: string
  items?: TableTranslationItem
}

export type TableTranslations = {
  [p: keyof TableData]: TableTranslationHead
}

export type TableActions = {
  icon: JSX.Element
  onClick: (e: TableData) => void
} & Without<IconButtonProps, "onClick">
export type TableClassNames = {
  root?: string
  search?: string
  table?: string
  column?: string
}

export type TableProps = {
  print?: boolean
  classNames?: TableClassNames
  cellTranslations: TableTranslations
  dataTableTranslations?: DataTableTranslations
  messages: TranslationMessages
  order?: TableOrder
  orderBy?: keyof TableData
  data: TableData[]
  iconActions?: TableActions[]
  showAvatar?: boolean
  handleSearch?: (value: string, data: TableData[]) => TableData[]
  handleClickCell?: (e: MouseEvent<unknown>, name: string) => void
} & MatTableProps

export const Table: FC<TableProps> = ({
  print,
  classNames,
  cellTranslations,
  dataTableTranslations,
  messages,
  order = "asc",
  orderBy,
  data = [],
  iconActions,
  showAvatar,
  handleSearch,
  handleClickCell,
  ...props
}) => {
  const [value, setValue] = useState("")
  const [Order, setOrder] = useState<TableOrder>(order)
  const [OrderBy, setOrderBy] = useState<keyof TableData>(
    orderBy ?? data?.[0]?.name ?? "",
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(print ? 1000000 : 10)

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleRequestSort = (
    e: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = OrderBy === property && Order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
    setRowsPerPage(10)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const renderTableRows = (data: TableData[]) => {
    return data?.map((row, index) => (
      <TableRow
        key={`${row.name}-${index}`}
        cellTranslations={cellTranslations}
        classNames={classNames}
        dataTableTranslations={dataTableTranslations}
        handleClickCell={handleClickCell}
        iconActions={iconActions}
        index={index}
        print={print}
        row={row}
        showAvatar={showAvatar}
      />
    ))
  }
  let filteredData = handleSearch
    ? handleSearch(value, stableSort(data, getComparator(Order, OrderBy)))
    : stableSort(data, getComparator(Order, OrderBy))
  const slicedFilteredData =
    filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ??
    []
  return (
    <div className={bem(undefined, undefined, classNames?.root)}>
      {handleSearch ? (
        <InputField
          className={bem("search", undefined, classNames?.search)}
          label={messages.components.common.table.searchInput}
          name="value"
          value={value}
          variant="outlined"
          onChange={handleChangeSearch}
        />
      ) : null}
      <TableContainer>
        <MatTable
          {...props}
          aria-labelledby="tableTitle"
          className={bem("table", undefined, classNames?.table)}
          size="medium"
          sx={{ minWidth: "100%" }}
        >
          <TableHead
            handleClickCell={handleClickCell}
            iconActions={iconActions}
            order={Order}
            orderBy={OrderBy}
            print={print}
            showAvatar={showAvatar}
            translations={cellTranslations}
            cells={
              Array.isArray(data) && data?.length > 0
                ? Object.keys(data?.[0]) ?? []
                : []
            }
            onRequestSort={handleRequestSort}
          />
          <TableBody>{renderTableRows(slicedFilteredData)}</TableBody>
        </MatTable>
      </TableContainer>
      {!print ? (
        <TablePagination
          className={bem("pargination")}
          component="div"
          count={filteredData?.length ?? 0}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          labelDisplayedRows={({ from, to, count }) => {
            const paginationPreposition =
              messages.components.common.table.pagination.paginationPreposition
            return `${from}–${to} ${paginationPreposition} ${
              count !== -1 ? count : `more than ${to}`
            }`
          }}
          labelRowsPerPage={
            messages.components.common.table.pagination.rowsPerPage
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  )
}
