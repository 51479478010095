import React, { FC, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import {
  useBookingDetailPermissions,
  mergeBookingDetailPermissions,
} from "src/states/bookingDetailPermissions"

import { TranslationMessages } from "src/translations"

import { PermissionTable } from "./PermissionTable"

export type PermissionDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const PermissionDialog: FC<PermissionDialogProps> = ({
  messages,
  style,
}) => {
  const m = messages.pages.booking.detail.actions.permissions
  const { open } = useBookingDetailPermissions()
  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="xl"
      open={useMemo(() => open, [open])}
      style={style}
      title={m.title}
      dialogButtons={[
        {
          title: m.cancel,
          variant: "text",
        },
      ]}
      onClose={() =>
        mergeBookingDetailPermissions({
          open: !open,
        })
      }
    >
      <PermissionTable messages={messages} />
    </Dialog>
  )
}
