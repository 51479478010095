import FormHelperText from "@mui/material/FormHelperText"
import React, { FC, MouseEventHandler, useState, useMemo } from "react"

import { Button } from "src/components/common/button"
import { Chips, ChipItem } from "src/components/common/chips"
import { Dialog } from "src/components/common/dialog"
import { TreeView, TreeViewItemProps } from "src/components/common/treeView"
import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./AdvancedFormFieldTreeView.module.scss"

const bem = create(styles, "AdvancedFormFieldTreeView")

type AdvancedFormFieldTreeViewItemProp = {
  id?: string
  label?: string
}

export type AdvancedFormFieldTreeViewProps = {
  messages?: TranslationMessages
  buttonLabel: string
  required?: boolean
  label?: string
  value?: string[]
  items?: TreeViewItemProps[]
  errorText?: string
  helperText?: string
  handleAddElement: (item: TreeViewItemProps) => void
  handleDeleteElement: (item: ChipItem) => void
  onValidate?: (error?: string) => void
}

export const AdvancedFormFieldTreeView: FC<AdvancedFormFieldTreeViewProps> = ({
  messages,
  required,
  buttonLabel,
  label,
  value,
  items,
  errorText,
  helperText,
  handleAddElement,
  handleDeleteElement,
}) => {
  const [open, setOpen] = useState(false)

  const validateActiveItems = (item: TreeViewItemProps): TreeViewItemProps => {
    return {
      ...item,
      checked: value?.includes(item?.id ?? "") ?? false,
      childs: item?.childs
        ? item?.childs?.map((a) => validateActiveItems(a))
        : undefined,
    }
  }
  const mergedItems = useMemo(() => {
    const elements = [] as AdvancedFormFieldTreeViewItemProp[]
    items?.forEach((a) => {
      elements.push({
        id: a?.id,
        label: a?.label?.toString(),
      })
      a?.childs?.forEach((b) => {
        elements.push({
          id: b?.id,
          label: b?.label?.toString(),
        })
        b?.childs?.forEach((c) => {
          elements.push({
            id: c?.id,
            label: c?.label?.toString(),
          })
        })
      })
    })
    return elements
  }, [items])
  const formatedItems = useMemo(
    () => items?.map((el) => validateActiveItems(el)),
    [items],
  )
  const chips = useMemo(
    () =>
      (value as string[])?.map((el) => {
        const index = mergedItems?.findIndex((i) => i.id === el)
        return {
          id: el,
          label: mergedItems?.[index]?.label,
        }
      }),
    [value],
  ) as ChipItem[]
  return (
    <div className={bem()}>
      <div className={bem("header")}>
        {label && (
          <span className={bem("label")}>
            {required ? `${label} *` : label}
          </span>
        )}
        <Button title={buttonLabel} onClick={() => setOpen(true)} />
      </div>
      <Dialog
        closeOnClick
        open={open}
        title={buttonLabel}
        dialogButtons={[
          {
            title: messages?.components?.common?.dialog?.buttons?.complete,
            variant: "text",
          },
        ]}
        onClose={() => setOpen(false)}
      >
        <TreeView
          enableSwitch
          items={formatedItems ?? []}
          onClick={
            handleAddElement as MouseEventHandler<HTMLUListElement> &
              ((item: TreeViewItemProps, name?: string | undefined) => void)
          }
        />
      </Dialog>
      {value && value.length > 0 ? (
        <Chips
          className={bem("chips")}
          items={chips}
          onDelete={handleDeleteElement}
        />
      ) : null}
      {errorText || helperText ? (
        <FormHelperText error={!!errorText}>
          {errorText ?? helperText}
        </FormHelperText>
      ) : null}
    </div>
  )
}
