import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import PersonAdd from "@mui/icons-material/PersonAdd"
import Print from "@mui/icons-material/Print"
import Security from "@mui/icons-material/Security"

import React, { forwardRef, useRef, ReactElement } from "react"
import { useReactToPrint } from "react-to-print"

import { Dropdown, DropdownRef } from "src/components/common/dropdown"
import { create } from "src/helpers/bem"

import { useAccount } from "src/states/account"
import { useBooking } from "src/states/bookingDetail"
import { mergeBookingDetailPermissions } from "src/states/bookingDetailPermissions"
import { mergeCoExhibitorInvite } from "src/states/coExhibitorInvite"

import { TranslationMessages } from "src/translations"
import { HeaderProps } from "src/types/SharedPageProps"

import styles from "./BookingDetailDropdown.module.scss"
import { BookingDetailPrint, printPageStyle } from "./BookingDetailPrint"

const bem = create(styles, "BookingDetailDropdown")

type BookingDetailDropdownItem = {
  sortBy: number
  key: string
  label: string
  icon: ReactElement
  onClick?: () => void
}

export type BookingDetailDropdownProps = {
  header: HeaderProps
  documentHeadline: string | null
  messages: TranslationMessages
}

export const BookingDetailDropdown = forwardRef<
  DropdownRef | undefined,
  BookingDetailDropdownProps
>(({ header, documentHeadline, messages }, ref) => {
  const { permissions } = useAccount()
  const { booking } = useBooking()
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: printPageStyle,
  })
  const m = messages.pages.booking.detail.actions

  const renderButton = () => {
    return (
      <span className={bem("button")}>
        <ArrowDropDown className={bem("button__icon")} />
        {m.button}
      </span>
    )
  }

  let dropdownItems = [
    // {
    //   sortBy: 1,
    //   key: "serviceRequests",
    //   label: m.serviceRequests.title,
    //   icon: <ContactSupport className={bem("dropdown__item__icon")} />,
    // },
  ] as BookingDetailDropdownItem[]
  const adminPermission = permissions?.some(
    (el) => el.salesorderId === booking?.id && el.role === "Administrator",
  )
  if (adminPermission) {
    dropdownItems = [
      ...dropdownItems,
      {
        sortBy: 3,
        key: "permissions",
        label: m.permissions.title,
        icon: <Security className={bem("dropdown__item__icon")} />,
        onClick: () =>
          mergeBookingDetailPermissions({
            open: true,
          }),
      },
    ]
  }

  if (booking?.phase !== "Draft") {
    dropdownItems = [
      ...dropdownItems,
      {
        sortBy: 0,
        key: "print",
        label: m.print.title,
        icon: <Print className={bem("dropdown__item__icon")} />,
        onClick: () => handlePrint(),
      },
    ]
    if (adminPermission) {
      dropdownItems = [
        ...dropdownItems,
        {
          sortBy: 2,
          key: "addCoExhibitor",
          label: m.addCoExhibitor.title,
          icon: <PersonAdd className={bem("dropdown__item__icon")} />,
          onClick: () =>
            mergeCoExhibitorInvite({
              open: true,
            }),
        },
      ]
    }
  }
  return (
    <div className={bem()}>
      <Dropdown ref={ref} button={renderButton()}>
        <ul className={bem("dropdown")}>
          {dropdownItems
            .sort((a, b) => a.sortBy - b.sortBy)
            .map((el) => (
              <li
                key={el.key}
                className={bem("dropdown__item")}
                onClick={el?.onClick}
              >
                {el.icon}
                <span className={bem("dropdown__item__label")}>{el.label}</span>
              </li>
            ))}
        </ul>
      </Dropdown>
      <div className={bem("print__view")}>
        <BookingDetailPrint
          ref={printRef}
          header={header}
          messages={messages}
          title={documentHeadline}
        />
      </div>
    </div>
  )
})

BookingDetailDropdown.displayName = "BookingDetailDropdown"
