import { resetAccount } from "src/states/account"
import { resetAccountForm } from "src/states/accountForm"
import { resetBooking } from "src/states/bookingDetail"
import { resetBookingDetailFiles } from "src/states/bookingDetailFiles"
import { resetBookings } from "src/states/bookings"
import { resetBookingsExternal } from "src/states/bookingsExternal"
import { resetSalesorder } from "src/states/salesorder"

export const resetStates = async () => {
  resetAccount()
  resetAccountForm()
  resetBookings()
  resetBooking()
  resetBookingsExternal()
  resetBookingDetailFiles()
  resetSalesorder()
}
