import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { AdvancedForms } from "./AdvancedForm"
import { AdvancedFormStepProps } from "./AdvancedFormStep"

import styles from "./AdvancedFormTitle.module.scss"

const bem = create(styles, "AdvancedFormTitle")

export type AdvancedFormTitleProps = {
  activeStep?: number
  data: AdvancedFormStepProps[] | AdvancedForms[]
  form?: AdvancedForms
  formMessages?: any
}

export const AdvancedFormTitle: FC<AdvancedFormTitleProps> = ({
  activeStep,
  data,
  form,
  formMessages,
}) => {
  const formId = form?.id ?? ""
  const formHelperText = form?.helperText ?? ""
  let activeStepTitle = ""
  if (activeStep !== undefined) {
    activeStepTitle = (formMessages?.[data?.[activeStep]?.id] as any)?.[formId]
      ?.title
  }
  return (
    <div className={bem()}>
      <h3 className={bem("headline")}>
        {form?.name ??
          form?.label ??
          (formMessages as any)?.[formId]?.title ??
          activeStepTitle ??
          ""}
      </h3>
      {formHelperText && <p className={bem("description")}>{formHelperText}</p>}
    </div>
  )
}
