import MatBox from "@mui/material/Box"
import MatStep from "@mui/material/Step"
import MatStepLabel from "@mui/material/StepLabel"
import MatStepper, {
  StepperProps as MatStepperProps,
} from "@mui/material/Stepper"

import React, { FC } from "react"

import { Button } from "../button"
import { create } from "src/helpers/bem"

import styles from "./Stepper.module.scss"

const bem = create(styles, "Stepper")

const MatBoxWrapperSx = {
  display: "flex",
  flexDirection: "row",
  pt: 2,
}

const MatBoxSxLeft = {
  flex: "1 1 auto",
}

const MatBoxSxRight = {
  flex: "1 1 auto",
  textAlign: "right",
}

const ButtonSx = {
  mr: 1,
}

export type StepLabel = {
  text: string
  onClick?: () => void
}

export type Step = {
  title: StepLabel
}

export type StepButton = {
  label: string
  disabled?: boolean
  onClick: () => void
}

export type StepperProps = {
  index: number
  steps: Step[]
  buttonsRight?: StepButton[]
  buttonsLeft?: StepButton[]
} & MatStepperProps

export const Stepper: FC<StepperProps> = ({
  index,
  steps,
  children,
  buttonsRight = [
    {
      label: "Next",
      onClick: () => undefined,
    },
  ],
  buttonsLeft = [
    {
      label: "Back",
      onClick: () => undefined,
    },
  ],
  ...props
}) => {
  const renderButton = (button: StepButton) => (
    <Button
      key={button.label}
      disabled={button?.disabled}
      sx={ButtonSx}
      title={button?.label}
      onClick={button.onClick}
    />
  )
  return (
    <MatBox className={bem()} sx={{ width: "100%" }}>
      <MatStepper alternativeLabel activeStep={index} {...props}>
        {steps.map((step) => {
          return (
            <MatStep key={step?.title?.text} onClick={step?.title?.onClick}>
              <MatStepLabel className={step?.title?.onClick && bem("label")}>
                {step?.title?.text}
              </MatStepLabel>
            </MatStep>
          )
        })}
      </MatStepper>
      <div>{children}</div>
      <MatBox sx={MatBoxWrapperSx}>
        <MatBox sx={MatBoxSxLeft}>
          {buttonsLeft && buttonsLeft?.length > 0
            ? buttonsLeft.map((button) => renderButton(button))
            : null}
        </MatBox>
        <MatBox sx={MatBoxSxRight}>
          {buttonsRight && buttonsRight?.length > 0
            ? buttonsRight.map((button) => renderButton(button))
            : null}
        </MatBox>
      </MatBox>
    </MatBox>
  )
}
