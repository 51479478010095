import CloseIcon from "@mui/icons-material/Close"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import React, { FC } from "react"

import { Button } from "src/components/common/button"
import { Drawer } from "src/components/common/drawer"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { ShopCartDialogProducts } from "src/components/services/shop/cartDialog/ShopCartDialogProducts"

import { create } from "src/helpers/bem"

import { useCart, mergeCart } from "src/states/shop/cart"
import { TranslationMessages } from "src/translations"

import styles from "./ShopCartDialog.module.scss"

const bem = create(styles, "ShopCartDialog")

export type ShopCartDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const ShopCartDialog: FC<ShopCartDialogProps> = ({
  messages,
  style,
}) => {
  const m = messages.pages.shop.cart.dialog
  const { cartDialogOpen, products } = useCart()

  const onCloseDialog = () => {
    mergeCart({
      cartDialogOpen: !cartDialogOpen,
    })
  }
  const buttonDisabled = !products || products?.length === 0
  return (
    <Drawer
      anchor="right"
      open={cartDialogOpen}
      style={style}
      onClose={onCloseDialog}
      onOpen={onCloseDialog}
    >
      <div className={bem()}>
        <div className={bem("header")}>
          <div className={bem("counter")}>
            <ShoppingBagOutlinedIcon className={bem("counter__icon")} />
            <h3 className={bem("counter__title")}>
              {products?.length ?? 0}{" "}
              {products && products?.length > 1 ? m.title2 : m.title2}
            </h3>
          </div>
          <button className={bem("close")} onClick={onCloseDialog}>
            <CloseIcon className={bem("close__icon")} />
          </button>
        </div>
        <div className={bem("products")}>
          <ShopCartDialogProducts messages={messages} />
        </div>
        <div className={bem("footer")}>
          <Button
            className={bem("button")}
            disabled={buttonDisabled}
            title={m.buttons.checkout}
            variant="contained"
            onClick={() => {
              console.log("JUMP TO CART!")
              // TODO: ADD ROUTER TO JUMP TO DETAILED CART PAGE
            }}
          />
          <Button
            disabled={buttonDisabled}
            title={m.buttons.cart}
            variant="outlined"
            classes={{
              root: bem("button"),
            }}
            onClick={() => {
              console.log("JUMP TO CHECKOUT!")
              // TODO: ADD ROUTER TO JUMP TO DETAILED CART PAGE
            }}
          />
        </div>
      </div>
    </Drawer>
  )
}
