import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { MenuLocationEnum_menuItems_nodes_childItems } from "src/types/wordpress/generated/MenuLocationEnum"

import styles from "./NavigationDropdown.module.scss"
import { NavigationDropdownItem } from "./NavigationDropdownItem"

const bem = create(styles, "NavigationDropdown")

export type NavigationDropdownProps = {
  className?: string
  title: string
  nodes: MenuLocationEnum_menuItems_nodes_childItems | null
}

export const NavigationDropdown: FC<NavigationDropdownProps> = ({
  className,
  title,
  nodes,
}) => {
  const items = nodes?.nodes && nodes?.nodes?.length > 0 ? nodes.nodes : []
  return (
    <ul
      className={bem(undefined, undefined, className)}
      data-cy="Navigation-Dropdown"
    >
      <li className={bem("title")}>{title}</li>
      {items.map((item) => {
        if (!item) {
          return null
        }
        return <NavigationDropdownItem key={item?.id} item={item} />
      })}
    </ul>
  )
}
