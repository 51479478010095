import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useState, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"

import { deleteSalesorderById } from "src/queriesXRM/salesoder"

import { removeCoExhibitorById } from "src/states/bookingDetail"
import {
  useCoExhibitorDelete,
  mergeCoExhibitorDelete,
} from "src/states/coExhibitorDelete"

import { TranslationMessages } from "src/translations"

import styles from "./DeleteCoExhibitorDialog.module.scss"

const bem = create(styles, "DeleteCoExhibitorDialog")

export type DeleteCoExhibitorDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const DeleteCoExhibitorDialog: FC<DeleteCoExhibitorDialogProps> = ({
  messages,
  style,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.booking.detail.actions.deleteCoExhibitor
  const { activeUser, open } = useCoExhibitorDelete()

  const onDeleteCoExhibitor = () => {
    setLoading(true)
    return deleteSalesorderById(activeUser?.id as string)
      .then((res) => {
        setLoading(false)
        removeCoExhibitorById(activeUser?.name ?? "")
        mergeCoExhibitorDelete({
          open: false,
          activeUser: null,
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      closeOnClick
      fullWidth
      color="error"
      maxWidth="sm"
      open={useMemo(() => open, [open]) ?? false}
      style={style}
      title={m.title}
      dialogButtons={[
        {
          title: m.delete,
          disabled: loading,
          color: "error",
          variant: "text",
          onClick: () => onDeleteCoExhibitor(),
        },
        {
          title: m.cancel,
          variant: "text",
          onClick: () =>
            mergeCoExhibitorDelete({
              open: false,
              activeUser: null,
            }),
        },
      ]}
    >
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <p className={bem("content")}>{m.content}</p>
      )}
    </Dialog>
  )
}
