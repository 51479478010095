import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import MatTableHead, {
  TableHeadProps as MatTableHeadProps,
} from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"

import visuallyHidden from "@mui/utils/visuallyHidden"
import React, { FC, Fragment, MouseEvent } from "react"

import { TableActions, TableData, TableOrder, TableTranslations } from "./Table"

export type TableHeadProps = {
  print?: boolean
  translations: TableTranslations
  orderBy: keyof TableData
  order: TableOrder
  cells: string[]
  iconActions?: TableActions[]
  showAvatar?: boolean
  onRequestSort: (event: MouseEvent<unknown>, property: keyof TableData) => void
  handleClickCell?: (e: MouseEvent<unknown>, name: string) => void
} & MatTableHeadProps

export const TableHead: FC<TableHeadProps> = ({
  print,
  translations,
  orderBy,
  order,
  cells,
  showAvatar,
  iconActions,
  onRequestSort,
  handleClickCell,
  ...props
}) => {
  const createSortHandler =
    (property: keyof TableData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  if (cells && cells.length === 0) {
    return null
  }
  const cellHasChildren = cells.includes("children")
  const filteredCells = cells.filter((el) => el !== "children")
  return (
    <MatTableHead {...props}>
      <TableRow>
        {cellHasChildren && !print ? <TableCell></TableCell> : null}
        {showAvatar ? <TableCell></TableCell> : null}
        {filteredCells.map((el, i) => (
          <Fragment key={`${el}-${i}`}>
            {translations[el] ? (
              <TableCell
                align="left"
                padding="normal"
                sortDirection={orderBy === el ? order : false}
              >
                <TableSortLabel
                  active={orderBy === el}
                  direction={orderBy === el ? order : "asc"}
                  onClick={createSortHandler(el)}
                >
                  {translations[el]?.label ?? "-"}
                  {orderBy === el ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : null}
          </Fragment>
        ))}
        {iconActions && iconActions.length > 0
          ? iconActions.map((icon, a) => (
              <TableCell key={`table-head-icon-action-${a}`}></TableCell>
            ))
          : null}
        {handleClickCell ? <TableCell></TableCell> : null}
      </TableRow>
    </MatTableHead>
  )
}
