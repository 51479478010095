import React, { FC, useMemo } from "react"

import {
  AdvancedForm,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"
import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { addNotification } from "src/states/notifications"
import { addProductToCart, CartProduct } from "src/states/shop/cart"
import {
  useProductFeatures,
  mergeProductFeatures,
  resetProductFeatures,
  resetProductFeaturesForm,
  mergeProductFeaturesFormField,
} from "src/states/shop/productFeatures"

import { TranslationMessages } from "src/translations"

export type ShopDetailProductFeatureDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const ShopDetailProductFeatureDialog: FC<
  ShopDetailProductFeatureDialogProps
> = ({ messages, style }) => {
  const m = messages.pages.shop.detail.productFeatureDialog
  const { open, product, form } = useProductFeatures()
  const mergedForms = useMemo(
    () =>
      [].concat.apply(
        [],
        Object.assign(form.map((el) => el.fields)),
      ) as AdvancedFormFieldProps[],
    [form],
  )

  const handleAddToCart = () => {
    /* TODO: Add function to add product to card by API */
    const fields = form?.[0]?.fields
    const fieldIndex = fields?.findIndex((el) => el?.name === "orderQuantity")
    const sum = fields?.[fieldIndex]?.value as string
    addProductToCart(product as CartProduct, sum ? parseFloat(sum) : undefined)
    mergeProductFeatures({
      open: !open,
    })
    resetProductFeatures()
    addNotification({
      autoclose: true,
      variant: "success",
      content: messages.pages.shop.cart.snackbar.add,
    })
  }

  const requiredFields = mergedForms
    .filter((el) => el.required)
    .map((el) => el["value"])
  const errors = mergedForms.filter((el) => el?.errorText)
  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="xl"
      open={useMemo(() => open, [open])}
      style={style}
      title={m.title}
      dialogButtons={[
        {
          title: m.add,
          variant: "contained",
          disabled:
            errors.length > 0 ||
            requiredFields.includes(undefined) ||
            requiredFields.includes(""),
          onClick: () => handleAddToCart(),
        },
        {
          title: m.cancel,
          variant: "text",
        },
      ]}
      onClose={() => {
        mergeProductFeatures({
          open: !open,
        })
        resetProductFeaturesForm()
      }}
    >
      <AdvancedForm
        data={form}
        handleMergeField={mergeProductFeaturesFormField}
        handleReset={resetProductFeaturesForm}
        messages={messages}
      />
    </Dialog>
  )
}
