import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { xRMApiAccount, xRMApiPermission } from "src/types/xRM"

export type Account = xRMApiAccount & {
  permissions?: xRMApiPermission[]
}

const { getValue, setValue, mergeValue, resetValue, useValue } =
  createSimpleGlobalState<Account>({}, "account")

export {
  getValue as getAccount,
  setValue as setAccount,
  mergeValue as mergeAccount,
  resetValue as resetAccount,
  useValue as useAccount,
}
