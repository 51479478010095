export const formatPrice = (
  price: number,
  locale?: string,
  options?: Intl.NumberFormatOptions,
) => {
  const formatter = new Intl.NumberFormat(locale ?? "de-DE", {
    style: "currency",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
    currency: options?.currency ?? "EUR",
  })
  return formatter.format(price)
}
