import { SelectChangeEvent } from "@mui/material/Select"
import { useRouter } from "next/router"
import React, { FC, useEffect, useMemo } from "react"

import { ButtonProps } from "src/components/common/button"
import { Dialog } from "src/components/common/dialog"
import { Select } from "src/components/common/select"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { create } from "src/helpers/bem"

import { useBookings, Booking } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import { useCart, mergeCart } from "src/states/shop/cart"
import { mergeProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewStandSelectionDialog.module.scss"

const bem = create(styles, "ShopOverviewStandSelectionDialog")

import { PagesProps } from "src/types/SharedPageProps"

export type ShopOverviewStandSelectionDialogProps = {
  messages: TranslationMessages
  pages: PagesProps
  style: BasicLayoutStyleProps
}

export const getPlacedBookings = (bookings: Booking[]) => {
  const filteredBookings = bookings.filter((el) => el?.phase === "Placed")
  return filteredBookings.map((booking) => ({
    key: booking?.id,
    value: booking?.name,
  }))
}

export const ShopOverviewStandSelectionDialog: FC<
  ShopOverviewStandSelectionDialogProps
> = ({ messages, pages, style }) => {
  const router = useRouter()
  const locale = useLocale()
  const bookings = useBookings()
  const { salesorderDialogOpen, activeSalesorderId } = useCart()
  const m = messages.pages.shop.overview.salesorderSelectionDialog

  useEffect(() => {
    // TODO: ONLY FOR TESTING: REMOVE THIS LINE IF FINAL LOGIC EXISTS
    mergeCart({
      salesorderDialogOpen: true,
      activeSalesorderId: "0",
    })
    // mergeCart({
    //     salesorderDialogOpen: !activeSalesorderId,
    // })
  }, [])

  const onAcceptDialog = () => {}

  const onCloseDialog = () => {
    mergeCart({
      salesorderDialogOpen: false,
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    const id = e?.target?.value as string
    mergeCart({
      activeSalesorderId: id,
    })
  }

  let formatedBookings = getPlacedBookings(bookings)
  if (formatedBookings?.length > 0) {
    formatedBookings = [
      {
        key: "0",
        value: `-- ${m.title} --`,
      },
      ...formatedBookings,
    ]
  }
  const redirectBookingForm = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Booking Form Template",
      )?.[0],
    [pages, locale],
  )
  const isDisabled = activeSalesorderId === "0"
  const dialogButtons = useMemo(() => {
    let buttons = [
      {
        title: m.buttons.jumpOver,
        variant: "text",
      },
    ] as ButtonProps[]
    if (formatedBookings?.length === 0) {
      buttons = [
        {
          title: m.buttons.bookNow,
          onClick: async () => {
            mergeProducts({
              loading: true,
            })
            await router.push(redirectBookingForm?.uri as string)
            mergeProducts({
              loading: false,
            })
          },
        },
        ...buttons,
      ]
    } else {
      buttons = [
        {
          title: m.buttons.apply,
          disabled: isDisabled,
          onClick: () => onAcceptDialog(),
        },
        ...buttons,
      ]
    }
    return buttons
  }, [m.buttons, redirectBookingForm, isDisabled, onAcceptDialog])
  return (
    <Dialog
      closeOnClick
      dialogButtons={dialogButtons}
      open={useMemo(() => salesorderDialogOpen, [salesorderDialogOpen])}
      style={style}
      title={m.title}
      onClose={onCloseDialog}
    >
      <div className={bem()}>
        <p className={bem("desc")}>{m.desc}</p>
        {formatedBookings?.length > 0 ? (
          <Select
            items={formatedBookings}
            title={m.input}
            value={activeSalesorderId}
            onChange={handleChangeSelect}
          />
        ) : (
          <p className={bem("helperText")}>{m.noData}</p>
        )}
      </div>
    </Dialog>
  )
}
