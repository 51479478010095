import {
  SetPartialStateAction,
  State,
  useHookstate,
  none,
} from "@hookstate/core"

import { createAspState } from "src/states/createAspState"
import {
  ProductFeaturesProduct,
  ProductFeaturesForm,
} from "src/states/shop/productFeatures"

export type CartProduct = ProductFeaturesProduct & {
  form: ProductFeaturesForm
  sum: number
}

export type Cart = {
  cartDialogOpen: boolean
  salesorderDialogOpen: boolean
  activeSalesorderId?: string
  // TODO: Replace type definition with API definition
  products?: CartProduct[]
}

export const defaultState = {
  cartDialogOpen: false,
  salesorderDialogOpen: false,
} as Cart

const state = createAspState<Cart>(defaultState, "shop-cart")

/**
 * Adds a product to cart
 * @param product the data data of the new/existing product to add
 */
export const addProductToCart = (product: CartProduct, sum?: number) => {
  const products = state.products.get()

  if (products && products?.length > 0) {
    const index = products.findIndex((el) => el?.id === product?.id)
    if (index >= 0) {
      ;(state.products as State<CartProduct[]>)
        .nested(index)
        .sum.set((e) => e + (sum ?? 1))
      return
    }
    state.products.set(
      JSON.parse(
        JSON.stringify([
          ...products,
          {
            ...product,
            sum: sum ?? 1,
          },
        ]),
      ),
    )
    return
  }
  state.products.set(
    JSON.parse(
      JSON.stringify([
        {
          ...product,
          sum: sum ?? 1,
        },
      ]),
    ),
  )
}

/**
 * Reduces the product sum in cart
 * @param id the id of the product to remove
 */
export const removeProductFromCart = (id: number) => {
  if (!id) {
    return
  }
  const products = state.products.get()
  if (products && products?.length > 0) {
    const index = products.findIndex((el) => el?.id === id)
    if (index < 0) {
      return
    }
    const product = (state.products as State<CartProduct[]>).nested(index)
    const productSum = product.sum.get()
    if (productSum <= 1) {
      product.set(none)
      return
    }
    product.sum.set((e) => e - 1)
  }
}

/**
 * Removes the complete product from cart
 * @param id the id of the product to remove
 */
export const removeCompleteProductFromCart = (id: number) => {
  if (!id) {
    return
  }
  const products = state.products.get()
  if (products && products?.length > 0) {
    const index = products.findIndex((el) => el?.id === id)
    if (index < 0) {
      return
    }
    ;(state.products as State<CartProduct[]>).nested(index).set(none)
  }
}

/**
 * Merges the state with new data
 * @param items the items of the state
 */
export const mergeCart = (items: SetPartialStateAction<Cart>) =>
  state.merge(items)

/**
 * Overwrites the state with new data
 * @param items the items of the state
 */
export const setCart = (items: Cart) => state.set(items)

/**
 * Resets the complete state
 */
export const resetCart = () => state.set(defaultState)

/**
 * Returns complete Cart added to the state.
 * @returns the Cart
 */
export const getCart = () => state.get()

/**
 * React hook to receive complete Cart from the state.
 * @returns the Cart
 */
export const useCart = () => {
  return useHookstate(state).get()
}
