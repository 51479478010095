import React, { FC } from "react"

import { SelectOption } from "src/components/common/select"

import { useCountries, Country } from "src/states/countries"
import { useNomenclature } from "src/states/nomenclature"
import { useOptionsets } from "src/states/optionsets"
import { TranslationMessages } from "src/translations"

import { AdvancedForms } from "./AdvancedForm"
import { AdvancedFormField, AdvancedFormFieldProps } from "./AdvancedFormField"
import { AdvancedFormStepProps } from "./AdvancedFormStep"

export type AdvancedFormFieldsProps = {
  activeStep?: number
  id?: string
  data: AdvancedFormStepProps[] | AdvancedForms[]
  formId: string
  formDisabled?: boolean
  formMessages?: any
  messages?: TranslationMessages
  fields?: AdvancedFormFieldProps[]
  updateFieldState: (value: AdvancedFormFieldProps, name?: string) => void
}

export const AdvancedFormFields: FC<AdvancedFormFieldsProps> = ({
  activeStep,
  data,
  formId,
  formDisabled,
  formMessages,
  messages,
  fields,
  updateFieldState,
}) => {
  const countries = useCountries()
  const nomenclature = useNomenclature()
  const { languages, academicTitles, companyTypes } = useOptionsets()
  if (!fields || fields?.length === 0) {
    return null
  }
  let fieldArray = [...fields]
  return (
    <>
      {fieldArray.map((fieldData) => {
        let Field = JSON.parse(JSON.stringify(fieldData))
        delete Field?.defaultConditions
        delete Field?.referenceType
        const {
          id,
          field,
          type,
          value,
          name = "",
          helperText,
          label,
          items,
          disabled,
        } = fieldData
        let Value = value
        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          Value = value?.value
        }
        const Disabled = disabled ?? formDisabled ?? false
        let Items = items ?? []
        const Name = `${formId}/${id ?? name}/${field}`

        let Label
        if (activeStep !== undefined) {
          Label =
            label ??
            (formMessages?.[data?.[activeStep]?.id]?.[formId] as any)?.fields?.[
              name ?? ""
            ] ??
            undefined
        } else {
          Label =
            label ??
            (formMessages?.[formId] as any)?.fields?.[name ?? ""] ??
            undefined
        }
        let HelperText =
          (formMessages?.[formId] as any)?.fields?.[
            `${name}HelperText` ?? ""
          ] ?? helperText
        if (!items || items?.length === 0) {
          switch (type?.toLowerCase()) {
            case "country":
              Items = countries as SelectOption[]
              Value = countries?.find(
                (el: Country) => el.key === Value,
              ) as SelectOption
              break
            case "nomenclature":
              Items = nomenclature as SelectOption[]
              break
            case "languages":
              Items = languages as SelectOption[]
              break
            case "academictitle":
              Items = academicTitles as SelectOption[]
              Value = academicTitles?.find(
                (el) => el.key === Value,
              ) as SelectOption
              break
            case "companytype":
              Items = companyTypes as SelectOption[]
              break
            default:
              Items = []
              break
          }
        }
        return (
          <AdvancedFormField
            {...Field}
            key={Name}
            disabled={Disabled}
            helperText={HelperText}
            id={id}
            items={Items}
            label={Label}
            messages={messages}
            name={Name}
            updateFieldState={updateFieldState}
            value={Value}
          />
        )
      })}
    </>
  )
}
