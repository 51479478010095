import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import { AdvancedForms } from "./AdvancedForm"
import { AdvancedFormFieldProps } from "./AdvancedFormField"
import { AdvancedFormFields } from "./AdvancedFormFields"
import styles from "./AdvancedFormStep.module.scss"
import { AdvancedFormTitle } from "./AdvancedFormTitle"

const bem = create(styles, "AdvancedFormStep")

export type AdvancedFormStepProps = {
  id: string
  name?: string
  label?: string
  description?: string
  position?: number
  forms: AdvancedForms[]
}

export type AdvancedFormStepComponentProps = {
  activeStep?: number
  data: AdvancedFormStepProps[] | AdvancedForms[]
  formDisabled?: boolean
  formMessages?: any
  messages?: TranslationMessages
  updateFieldState: (value: AdvancedFormFieldProps, name?: string) => void
}

export const AdvancedFormStep: FC<AdvancedFormStepComponentProps> = ({
  activeStep,
  data,
  formDisabled,
  formMessages,
  messages,
  updateFieldState,
}) => {
  if (!data || data?.length === 0) {
    return null
  }
  return (
    <div className={bem()}>
      {activeStep !== undefined &&
      (data[activeStep] as AdvancedFormStepProps)?.forms &&
      (data[activeStep] as AdvancedFormStepProps)?.forms?.length > 0 ? (
        <div className={bem("forms")}>
          {(data[activeStep] as AdvancedFormStepProps)?.forms?.map((form) => {
            const display =
              form?.conditions?.display === false ? "none" : "block"
            return (
              <div key={form.id} className={bem("form")} style={{ display }}>
                <AdvancedFormTitle
                  activeStep={activeStep}
                  data={data}
                  form={form}
                  formMessages={formMessages}
                />
                <AdvancedFormFields
                  activeStep={activeStep}
                  data={data}
                  fields={form?.fields}
                  formDisabled={formDisabled}
                  formId={form?.id}
                  formMessages={formMessages}
                  messages={messages}
                  updateFieldState={updateFieldState}
                />
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
