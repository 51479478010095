import InfoIcon from "@mui/icons-material/InfoOutlined"
import React, { FC } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"

import { create } from "src/helpers/bem"
import { mergeCoExhibitorInvite } from "src/states/coExhibitorInvite"

import { TranslationMessages } from "src/translations"

import styles from "./BookingDetailBanner.module.scss"

const bem = create(styles, "BookingDetailBanner")

export type BookingDetailBannerProps = {
  messages: TranslationMessages
}

export const BookingDetailBanner: FC<BookingDetailBannerProps> = ({
  messages,
}) => {
  const m = messages.pages.booking.detail.banner
  return (
    <div className={bem()}>
      <Card>
        <div className={bem("title__wrapper")}>
          <div className={bem("title__icon")}>
            <InfoIcon className={bem("icon")} fontSize="small" />
          </div>
          <h5 className={bem("title")}>{m.title}</h5>
        </div>
        <div className={bem("content")}>
          <div dangerouslySetInnerHTML={{ __html: m.content }} />
          <Button
            className={bem("button")}
            title={m.button}
            onClick={() =>
              mergeCoExhibitorInvite({
                open: true,
              })
            }
          />
        </div>
      </Card>
    </div>
  )
}
