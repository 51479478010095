import HighlightOff from "@mui/icons-material/HighlightOff"
import React, { FC, useEffect } from "react"

import { Table, TableData } from "src/components/common/table"

import { create } from "src/helpers/bem"

import { fetchCoExhibitors } from "src/helpers/fetchQueriesXRM"
import { BookingCoExhibitor, useBooking } from "src/states/bookingDetail"
import { mergeCoExhibitorDelete } from "src/states/coExhibitorDelete"
import { useLocale } from "src/states/locale"

import { TranslationMessages } from "src/translations"

import styles from "./BookingDetailCoExhibitorTable.module.scss"

const bem = create(styles, "BookingDetailCoExhibitorTable")

export type BookingDetailCoExhibitorTableProps = {
  messages: TranslationMessages
}

export const BookingDetailCoExhibitorTable: FC<
  BookingDetailCoExhibitorTableProps
> = ({ messages }) => {
  const m = messages.pages.booking.detail.coExhibitors
  const locale = useLocale()
  const { booking, coExhibitors } = useBooking()

  useEffect(() => {
    fetchCoExhibitors(booking?.id ?? "")
  }, [booking?.id, locale])

  const handleSearchTable = (value: string, data: TableData[]) => {
    const Value = value.toLowerCase()
    return data.filter((item) => {
      if (!Value) return true
      if (
        item.name?.toLowerCase()?.includes(Value) ||
        (item.exhibitor as string)?.toLowerCase()?.includes(Value) ||
        (item.exhibition as string)?.toLowerCase()?.includes(Value) ||
        (typeof item?.client === "string" &&
          item?.client?.toLowerCase()?.includes(Value))
      ) {
        return true
      }
    })
  }

  return (
    <div className={bem()}>
      <h3 className={bem("headline")}>{m.headline}</h3>
      {coExhibitors && coExhibitors.length > 0 ? (
        <Table
          cellTranslations={m.table}
          data={coExhibitors as TableData[]}
          dataTableTranslations={m.dataTable}
          handleSearch={handleSearchTable}
          messages={messages}
          order="desc"
          orderBy="date"
          iconActions={[
            {
              icon: <HighlightOff />,
              color: "error",
              onClick: (e) =>
                mergeCoExhibitorDelete({
                  open: true,
                  activeUser: e as BookingCoExhibitor,
                }),
            },
          ]}
        />
      ) : (
        <p>{m.noData}</p>
      )}
    </div>
  )
}
