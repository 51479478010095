import MatDialog, { DialogProps as MatDialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"

import { TransitionProps } from "@mui/material/transitions"
import React, { FC, ReactNode } from "react"

import { Button, ButtonProps } from "src/components/common/button"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide ref={ref} direction="up" {...props} />
})

export type DialogProps = {
  title?: string
  titleClassName?: string
  dialogButtons?: ButtonProps[]
  closeOnClick?: boolean
  children: ReactNode | string
} & MatDialogProps

export const Dialog: FC<DialogProps> = ({
  open,
  color = "primary",
  title,
  titleClassName,
  dialogButtons,
  onClose,
  closeOnClick,
  children,
  ...props
}) => (
  <MatDialog
    {...props}
    keepMounted
    open={open}
    TransitionComponent={Transition}
    onClose={
      closeOnClick
        ? (e, val) => {
            onClose?.(e, val)
          }
        : undefined
    }
  >
    {title ? (
      <DialogTitle className={titleClassName} color={color}>
        {title}
      </DialogTitle>
    ) : null}
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {dialogButtons && dialogButtons?.length > 0
        ? dialogButtons.map((button, i) => (
            <Button
              {...button}
              key={`dialog-button-${i}`}
              onClick={(e) => {
                button?.onClick?.(e)
                onClose?.(e, "backdropClick")
              }}
            >
              {button.title}
            </Button>
          ))
        : null}
    </DialogActions>
  </MatDialog>
)
