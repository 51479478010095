import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { xRMApiSalesorderType, xRMApiSalesorderPhase } from "src/types/xRM"

export interface Booking {
  name: string
  [p: string]: number | string | xRMApiSalesorderPhase | xRMApiSalesorderType
}

const { getValue, setValue, resetValue, useValue } = createSimpleGlobalState<
  Booking[]
>([], "bookings")

export {
  getValue as getBookings,
  setValue as setBookings,
  resetValue as resetBookings,
  useValue as useBookings,
}
