import CloseIcon from "@mui/icons-material/Close"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import React, { FC, forwardRef } from "react"

import { useNotifications, removeNotification } from "src/states/notifications"
import { TranslationMessages } from "src/translations"

export type SnackbarProps = {
  messages?: TranslationMessages
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />
})

export const Snackbar: FC<SnackbarProps> = ({ messages }) => {
  const Messages = (messages as any)?.components?.core?.snackbar
  const notifications = useNotifications()

  if (notifications.length === 0) {
    return null
  }

  return (
    <Stack
      spacing={2}
      sx={{
        maxWidth: 600,
        position: "fixed",
        right: 20,
        bottom: 20,
        zIndex: 9999,
      }}
    >
      {notifications.map((notification) => {
        let snackbarContent = Messages?.[notification?.variant]?.default
        if (notification?.content) {
          snackbarContent = notification?.content
        } else if (notification?.code && notification.variant) {
          snackbarContent =
            Messages?.[notification?.variant]?.[notification?.code]
        }
        return (
          <Alert
            key={notification.id}
            severity={notification.variant}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => removeNotification(notification?.id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {snackbarContent}
          </Alert>
        )
      })}
    </Stack>
  )
}
